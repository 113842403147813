import {React, useState, useEffect} from 'react';
import './About.scss';
import {motion} from 'framer-motion';
import {client, urlFor} from '../../client';
import { AppWrap, MotionWrap } from '../../wrapper';

const About = () => {

  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query)
    .then((data) => {
      setAbouts(data);
    });

  }, []);

  return (
    <>
      <h2 className='app__head-text'>Bringing <span>Ideas</span> to Life <br /> With <span>Code</span> </h2>

        <div className='app__profiles'>
          {abouts.map((about, index) => (
            <motion.div
              whileInView={{opacity: 1}}
              whileHover={{scale: 1.1}}
              transition={{duration: 0.5, type: `tween`}}
              className='app__profile-item'
              key= {about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt={about.title} />
              <h2 className='app__color--secondary' style={{marginTop: 20}}>{about.title}</h2>
              <p className='app__p-text app__color--white' style={{marginTop: 10}}>{about.description}</p>
            </motion.div>
          ))}
        </div>
          <motion.div
            whileInView={{opacity: 1}}
            transition={{duration: 0.5, opacity: 0.5}}
            className='app__flex-center'
          >
            <p className='app__bio app__p-text app__color--white'>
            Hello there, I am a recent EWU graduate with a degree in Computer Science and a focus in Design. 
            I enjoy creating things and helping people bring their ideas to life. 
            <br/><br/>
            I am proficient in Java and have experience programming in JavaScript, C, PHP, SQL. Other technologies 
            I have worked with include Git, React, Laravel and AWS (Lambda, S3, API Gateway). 
            <br/><br/>
            I also have experience with UX design/research and web design. 
            </p>
          </motion.div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'), 
  'about', 
  'app__whitebg'
  );