import {React, useEffect, useState} from 'react';
import './Navbar.scss';
import {images} from '../../constants';
import {HiMenuAlt4, HiX} from 'react-icons/hi';
import {client} from '../../client';
import {motion} from 'framer-motion';

function Navbar() {
    const [toggle, setToggle] = useState(false);

    const [resume, setResume] = useState([]);

    useEffect(() => {
      const query = `*[_type == "resume"]{
        "resumeLink": file.asset->url
      }`;
  
      client.fetch(query)
      .then((data) => {
        setResume(data[0].resumeLink);
      });
  
    }, []);


  return (
    <nav className="app__navbar backdrop-blur">
        <div className='app__navbar-logo'>
            <img src={images.logoph} alt="logo" />
        </div>
        <ul className='app__navbar-links'>
            {['home', 'about', 'projects' , 'contact'].map((item) => (
                <li className='app__flex-center p-text' key={`link-${item}`}>
                    <div />
                    <a href={`#${item}`}>{item}</a>
                </li>
            ))}
            <li className='app__flex-center p-text'>
                    <div />
                    <a href={`${resume}`} target="_blank" rel="noreferrer">Resume</a>
            </li>
        </ul>

        <div className='app__navbar-menu'>
            <HiMenuAlt4 onClick={() => setToggle(true)}/>

            {
                toggle && (
                    <motion.div
                        whileInView={{x:[300, 0]}}
                        transition={{duration: 0.85, ease: 'easeOut'}}
                    >
                        <HiX onClick={() => setToggle(false)}/>
                        <ul>
                            {['home', 'about', 'projects', 'contact'].map((item) => (
                                <li className='app__flex-center p-text' key={`${item}`}>
                                    <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
                                </li>
                            ))}
                            <li className='app__flex-center p-text'>
                                <a href={`${resume}`} target="_blank" rel="noreferrer">Resume</a>
                            </li>
                         </ul>
                    </motion.div>
                )}
        </div>

    </nav>
  )
}

export default Navbar