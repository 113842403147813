import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import react from '../assets/react.png';
import sass from '../assets/sass.png';
import java from '../assets/java.png';



import me from '../assets/me.webp';
import circle from '../assets/circle.svg';
import logoph from '../assets/logo.svg'

export default {
  cpp,
  css,
  figma,
  git,
  html,
  javascript,
  node,
  react,
  sass,
  circle,
  me,
  logoph,
  java
};