import './App.scss';
import React from 'react'
import {About, Footer, Header, Projects, Skills} from './container';
import { Navbar } from './components';

export const App = () => {
  return (
    <div className='app'>
      <Navbar />
      <Header />
      <About />
      <Projects />
      <Footer />
    </div>
  );
}


export default App;
