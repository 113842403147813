import {React, useState, useEffect} from 'react'
import './Projects.scss'
import {AiFillEye, AiFillGithub} from 'react-icons/ai'
import {CgFigma} from 'react-icons/cg'
import {motion} from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'

const Projects = () => {

  const [animateCard, setAnimateCard] = useState({y:0, opacity:1})

  const [activeFilter, setActiveFilter] = useState('all')

  const [projects, setProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])

  useEffect(() => {
    const query = `*[_type == "projects"]`;

    client.fetch(query)
    .then((projects) => {
      setProjects(projects);
      setFilteredProjects(projects);
    });

  }, [])

  const handleprojectFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard({y:100, opacity:0});
    setTimeout(() => {
      setAnimateCard({y:0, opacity:1});

      if(item === 'all'){
        setFilteredProjects(projects);
      }else{
        setFilteredProjects(projects.filter((project) => project.tags.includes(item)));
      }
    }, 500);
  }

  return (
    <>
      <h2 className='app__head-text'>My <span>Projects</span> </h2>

      <div className='app__project-filter'>
        {
          ['ux', 'web', 'software', 'all'].map((item, index) => (
            <div 
              key={index}
              onClick={() => handleprojectFilter(item)}
              className = {`app__project-filter-item app__flex-center app__color--gray app__uppercase-text ${activeFilter === item ? 'item-active' : '' }`}
            >
              {item}
            </div>
          ))
        }
      </div>

      <motion.div
        animate = {animateCard}
        transition = {{duration: 0.5, delayChildren: 0.5}}
        className='app__project-portfolio'
      >
        {
          filteredProjects.map((project, index) => (
            <div className='app__project-item app__flex-center' key={`$proj-${index}`}>
              <div className='app__project-img app__flex-center'>
                <img src={urlFor(project.projectImage)} alt={project.name} />

                <motion.div
                  whileHover={{opacity: [0,1]}}
                  transition={{duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5}}
                  className='app__project-hover app__flex-center'
                >
                  {project.projectLink !== 'none' && 
                  (<a href={project.projectLink} target='_blank' rel='noreferrer'>
                  <motion.div
                     whileInView={{scale: [0,1]}}
                     whileHover={{scale: [1,0.9]}}
                     transition={{duration: 0.25}}
                     className='app__flex-center'
                  >
                    <AiFillEye />
                  </motion.div>
                </a>)}

                  <a href={project.codeLink} target='_blank' rel='noreferrer'>
                    <motion.div
                       whileInView={{scale: [0,1]}}
                       whileHover={{scale: [1,0.9]}}
                       transition={{duration: 0.25}}
                       className='app__flex-center'
                    >
                      {(project.icon === 'github') ? (<AiFillGithub/>): (<CgFigma />)}
                    </motion.div>
                  </a>

                </motion.div>
              </div>

                <div className='app__project-content app__flex-center'>
                  <p className='app__project-title app__p-text'>{project.title}</p>
                  <p className='app__p-text app__color--gray' style={{marginTop: 10}}>{project.description}</p>

                  <div className='app__project-tag backdrop-blur app__flex-center'>
                    <p className='app__p-text app__color--white app__uppercase-text'>{project.tags[0]}</p>
                  </div>

                  <div className='app__project-links'>
                    {project.projectLink !== 'none' &&
                    (<div>
                      <a href={project.projectLink} rel="noreferrer" target="_blank"><AiFillEye/></a>
                    </div>)}

                    <div>
                      <a href={project.codeLink} rel="noreferrer" target="_blank">{(project.icon === 'github') ? (<AiFillGithub/>): (<CgFigma />)}</a>
                    </div>
                  </div>
                </div>
            </div>
          ))}

      </motion.div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Projects, 'app__projects'),
  'projects',
  "app__primary-bg"
  );