import React from 'react'
import {BsGithub, BsLinkedin} from 'react-icons/bs'

const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
            <a href="https://github.com/cmartinezaraujo" rel="noreferrer" target="_blank"><BsGithub/></a>
        </div>

        <div>
            <a href="https://www.linkedin.com/in/cesar-martinez-302b4322b/" rel="noreferrer" target="_blank"><BsLinkedin/></a>
        </div>
    </div>
  )

}

export default SocialMedia