import React from 'react'

const NavigationDots = ({active}) => {
  return (
    <div className="app__navigation">
        {['home', 'about', 'projects', 'contact'].map((item, index) => (
            <a 
                href={`#${item}`}
                key = {item+index}
                className ="app__navigation-dot"
                style={active === item ? {backgroundColor: 'rgba(134, 160, 252, 1)'} : {}}
            />
        ))}
    </div>
  )
}

export default NavigationDots;