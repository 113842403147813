import {React, useState} from 'react'
import './Footer.scss'
import {AppWrap, MotionWrap} from '../../wrapper'
import {client} from '../../client'
import {IoIosMail} from 'react-icons/io'
import {BsGithub, BsLinkedin} from 'react-icons/bs'


const Footer = () => {
  const [formData, setFormData] = useState({name: '', email: '', message: ''})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const {name, email, message} = formData;

  const handleChangeInput = (e) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value})
  }

  const handleSubmit = () => {
    setLoading(true)

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message
    }

    client.create(contact)
      .then(() => {
        setLoading(false)
        setIsFormSubmitted(true)
      })
  }

  return (
    <>
      <h2 className='app__head-text'>Lets get in <span>touch</span></h2>
      <div className='app__footer-cards'>

        <div className='app__footer-card'>
          <IoIosMail />
          <a href="mailto:cesarmartinezaraujo@outlook.com" className='app__p-text app__color--black'>CesarMartinezAraujo@outlook.com</a>
        </div>

        <div className="footer__social">
        <div>
            <a href="https://github.com/cmartinezaraujo" rel="noreferrer" target="_blank"><BsGithub/></a>
        </div>

        <div>
            <a href="https://www.linkedin.com/in/cesar-martinez-302b4322b/" rel="noreferrer" target="_blank"><BsLinkedin/></a>
        </div>
    </div>

      </div>

      {!isFormSubmitted ?
        <div className='app__footer-form app__flex-center'>
          <div className='app__flex-center'>
            <input type="text" className='app__p-text' placeholder='Your Name' name='name' value={name} onChange={handleChangeInput}/>
          </div>

          <div className='app__flex-center'>
            <input type="text" className='app__p-text' placeholder='Your Email' name='email' value={email} onChange={handleChangeInput}/>
          </div>

          <div>
            <textarea
              className='app__p-text'
              placeholder='Your Message'
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>

          <button type='button' className='app__p-text' onClick={handleSubmit}>{loading ? 'Sending': 'Send Message'}</button>
        </div>
        : <div>
          <h3 className='app__head-text'>Thank you for getting in touch.</h3>
        </div>
      }
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
)