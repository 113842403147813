import React from 'react'
import './Header.scss'
import {motion} from 'framer-motion';
import { images } from '../../constants';
import {AppWrap} from '../../wrapper';

const scaleVariants = {
  whileInView: {
    scale: [0,1],
    opacity: [0,1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

function Header() {
  return (
    <div className='app__header app__flex-center'>
      <motion.div
        whileInView={{x: [-100, 0], opacity: [0, 1]}}
        transition={{duration: 0.5}}
        className='app__header-info'
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex-center'>
            <span>👋</span>
            <div style={{marginLeft: 20}}>
              <p className='greeting app__color--white'>Hello, I am</p>
              <h1 className='app__head-text app__color--white'>Cesar</h1>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1], delayChildren: 0.5}}
        transition={{duration: 0.5}}
        className='app__header-img'
      >
          <motion.img
            whileInView={{ scale: [0, 1] }}
            transition={{duration: 1, ease: 'easeInOut'}}
            src={images.circle}
            alt="profile-circle"
            className = "overlay_circle"
          />
        <img className='profile-me' src={images.me} alt="profile-background" />
          
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className='app__header-circles'
      >

          <div className='circle-cmp app__flex-center'>
            <img src={images.figma} alt="Figma logo" />
          </div>

          <div className='circle-cmp app__flex-center'>
            <img src={images.java} alt="Java logo" />
          </div>

          <div className='circle-cmp app__flex-center'>
            <img src={images.html} alt="HTML logo" />
          </div>
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home');